













































@import '../../utilities/config';

.was-validated .form-check-input:valid ~ .form-check-label {
  color: gray('gray-750');
}
