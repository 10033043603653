






































































@import '../../utilities/config';

.custom-radio {
  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-color: #639113;
      border-radius: 8px;
    }
  }
}

.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: gray('gray-750');
}
